import { Component, cloneElement, toChildArray, createRef} from "preact";
import { createPortal } from "preact/compat";
import { withScroll } from "../page/scroll-element";
import { editorOverlayAPI } from "./editor-overlay-controller"

import _ from 'lodash';


class EditableOverlay extends Component {

	constructor(props){
		super(props);

		this.state = {
			scrollIncrement: 0,
			pointerAttention: false,
			overlayPosition: {
				x: 0,
				y: 0,
				width: 0,
				height: 0,
			}			
		}

		this.attachToBaseNode();

	}

	render(props, state){

		if(!this.renderElement) {
			return null;
		}

		return createPortal(<>{
			props.render({...state, ...props})
		}</>, this.renderElement)
	}

	pointerIn = (e)=>{
		this.requestTick();
		this.setState((prevState)=>{

			return {
				pointerAttention: true,
				scrollIncrement: prevState.scrollIncrement+1
			}
		})

		if(this.props.onPointerIn){
			this.props.onPointerIn();
		}
	}	

	pointerOut = ()=>{
		
		this.setState({
			pointerAttention: false
		})		
		if(this.props.onPointerOut){
			this.props.onPointerOut();			
		}		
	}	

	updatePosition = (rect)=>{
		const buttonHeight = this.props.buttonHeight || 0;

		let dip = 0;
		let height = rect.height;

		let y = rect.top;
		if( this.props.buttonMode == 'inside'){

			height = rect.height;

		} else {
			// uncomment and change the relevant extraMargins to [this.props.buttonHeight, 0,this.props.buttonHeight, 0]
			// in order to bring hover-state to outside edges
			// y = rect.y + buttonHeight;
			// height = rect.height + -buttonHeight*2; 
			height = rect.height;
		}
	

		this.setState({
			overlayPosition: {
				x: rect.left,
				y: y,
				width: rect.width,
				height: height,
			}
		})
	}

	componentWillUnmount(){
		this.props.baseNode._wasFocused = this.state.pointerAttention
		this.props.scrollContext.scrollingElement.removeEventListener('scroll', this.onScroll);			
		editorOverlayAPI.removePortal(this.props.baseNode, this.renderElement);
	}

	requestTick = ()=>{
		if(this.ticking){
			return;
		}
		editorOverlayAPI.updateElementRect(this.props.baseNode);

		this.ticking =true;
		requestAnimationFrame(()=>{
			this.ticking = false;
		})
	}

	attachToBaseNode() {

		if(!this.props.baseNode) {
			return;
		}

		this.renderElement = editorOverlayAPI.getPortal(this.props.baseNode, {	
			noPointerEvents: this.props.noPointerEvents || false,
			trackResize: this.props.trackResize || false,
			extraMargins: this.props.buttonMode == 'inside' ?  [0, 0, 0, 0] : [0, 0, 0, 0] || false,
			component: this,
		});

	}

	onScroll = (e)=>{
		this.requestTick();
		const y = this.props.scrollContext.getScrollPosition().y
		const delta = y - this.scrollPos;
		this.scrollPos = y;
		
		if( this.props.onScrollWhileHovered){
			this.props.onScrollWhileHovered(delta);
		}
	}

	componentDidMount(){
		
		if(this.props.baseNode && this.props.baseNode._wasFocused ){
			this.setState({
				pointerAttention: true
			});
			this.requestTick();
		}
		editorOverlayAPI.testHover();
	}

	componentDidUpdate(prevProps, prevState){

		if(this.props.baseNode && prevProps.baseNode !== this.props.baseNode) {
			this.attachToBaseNode();
		}

		if(
			this.state.pointerAttention !== prevState.pointerAttention ||
			this.state.noPointerEvents !== prevState.noPointerEvents ||
			this.props.hasFocus !== prevProps.hasFocus ||
			this.props.scrollContext.scrollingElement !== prevProps.scrollContext.scrollingElement 
		) {

			if( this.props.scrollContext.scrollingElement &&
				( this.props.hasFocus || this.state.pointerAttention || this.state.noPointerEvents)
			){

				this.scrollPos = this.props.scrollContext.getScrollPosition().y
				this.props.scrollContext.scrollingElement.addEventListener('scroll', this.onScroll)

			} else if(prevProps.scrollContext.scrollingElement){

				prevProps.scrollContext.scrollingElement.removeEventListener('scroll', this.onScroll);	
			}

			editorOverlayAPI.testHover();
			this.requestTick();

		}


	}

}

EditableOverlay.defaultProps = {
	buttonMode: 'inside', // 'inside' == audio-player, commerce widget, etc. 'outside' == column-set
	buttonHeight: 0,
}

export default withScroll(EditableOverlay);