import React, {useState, useEffect, useRef} from 'react';

export const ContextMenuCheckbox = ({ label, value, onCheckboxChange, name, id, onCheckboxBlur, onPointerUp, icon, classList, ...otherProps }) => {

	const isChecked = value === true || (value === "on");

	// State from "passed" props in unique instances.
	const [hover, setHover] = useState(false);
	const [flickerOn, setFlickerOn] = useState(false);
	const [flickerOff, setFlickerOff] = useState(false);

	const [allowPointerUp, setAllowPointerUp] = useState(false);
	const timeoutRef = useRef(null);

	// don't allow a close and pointer-up in the first ~150ms to let user trigger a
	// menu that stays open even if the pointer isnt down
    useEffect(() => {
    	timeoutRef.current = setTimeout(()=>{
    		setAllowPointerUp(true)
    	}, 150);
    	return ()=>{
    		clearTimeout(timeoutRef.current)
    	}
    }, []);

	return (
		<label
			type={`checkbox`}
			htmlFor={id}
			checked-on={`${isChecked ? 'true': ''}`}
			className={`${hover ? 'hover' : '' }${flickerOn ? ' flicker-on' : '' }${flickerOff ? ' flicker-off' : '' }${classList ? ' '+classList : ''}`}
			onPointerEnter={() => setHover(true) }
			onPointerLeave={() => setHover(false) }
			onPointerUp={ (e) => {

				if( !allowPointerUp){
					e.stopPropagation();
					return
				}
				setFlickerOff(true)
				setTimeout(() => {
					setFlickerOn(true)
					setFlickerOff(false)
					setTimeout( ()=>{
						onPointerUp(e);

						// reset flicker state after pointer up in case it's a multi-select
						setTimeout(()=>{
							setFlickerOn(false)
							setFlickerOff(false)							
						}, 90);


					}, 90)
				}, 90)
			}}
			{...otherProps}
		>
			<span className="before"></span>
			<div className="checkbox-label">
				{label}
			</div>
			<input
				type="checkbox"
				name={id}
				id={id}
				checked={isChecked}
				onBlur={onCheckboxBlur}
				readOnly
			/>
			<span className={`after${icon ? ' icon' : ''}`}>{icon}</span>
		</label>
	);
}
