import { Component, createElement, toChildArray } from "preact";
import { PureComponent, forwardRef } from 'preact/compat';
import { connect } from 'react-redux';
import _ from 'lodash';
import { helpers } from "@cargo/common";
import selectors from "../../selectors";
import { getScaleSize, getImageWithOptions} from "../../helpers";

const imageKeys = new Set();

class Image extends Component {

	constructor(props) {
		super(props);

		this.state = {
			src: null,
			lastSrc: null,
			loaded: false,
		}

		this.uid = _.uniqueId();
		this.decodingQueue = [];
	}

	blankURI = 'data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7'

	render(props, state){
		const {
			model,
			adminMode,
			isZoomable,
			alt,
			hash,
			src,
			decoding,
		} = props;
		const width = props.width ?? model?.width ?? 1600;
		const height =  props.height ?? model?.height ?? 1600;

		let usableSrc = this.state.src || this.blankURI;

		if( !imageKeys.has(this.state.src) ){
			usableSrc = this.state.lastSrc || this.blankURI;
		}

		usableSrc = this.props.isLazyLoadable ? usableSrc : this.blankURI;

		return createElement('img', {
			onClick: this.props.onClick,
			crossorigin: '',
			alt: alt || null,
			src: usableSrc,
			width: width,
			height: height,
			className: `media${ isZoomable ? ' image-zoom' : ''}` + ' '+ (this.props.className || ''),
			ref: this.props.mediaRef,
			onLoad: this.onLoad,
			decoding: 'sync',
			part: this.props.part || undefined,
		});

	}


	onLoad = (e)=>{


		if( !this.props.mediaRef.current ){
			return
		}
		if(
			this.props.mediaRef.current.src === this.blankURI ||
			this.props.mediaRef.current.src === ''
		){
			return
		}


		this.setState({
			loaded: true,
		})

		if(this.props.onLoad){
			if (this.props.model){
				this.props.onLoad({
					width: this.props.model.width,
					height: this.props.model.width,
				});				
			} else {
				this.props.onLoad({
					width: this.props.mediaRef.current.naturalWidth,
					height: this.props.mediaRef.current.naturalHeight
				});				
			}
			

		}

	}

	componentDidUpdate(prevProps, prevState){


		const propSrc = this.props['dynamic-src'] || this.props.src || undefined;
		const prevPropSrc = prevProps['dynamic-src'] || prevProps.src || undefined;
		if(
			this.props.renderWidth !== prevProps.renderWidth ||
			this.props.renderHeight !== prevProps.renderHeight ||

			prevProps.isLazyLoadable !== this.props.isLazyLoadable ||
			propSrc !== prevPropSrc ||
			(
				this.props.model && 
				(
					this.props.model?.hash !== prevProps.model?.hash
				)

			)
		){
			this.onSizeChange();	
		}

	}

	componentDidMount() {
		this.firstLoad = true;

		this.onSizeChange();
	}

	decodeImage = (src, srcKey)=>{

		if( this.decodingQueue.indexOf(src) >-1  ){
			return
		}

		this.decodingQueue.push(src);
		const loadImage = document.createElement('img');

		const onFinish = ()=>{

			loadImage.onload = null;
			if( !imageKeys.has(loadImage.src) ) {
				imageKeys.add(loadImage.src);

				if(this.state.src ==loadImage.src ){
					this.forceUpdate();
				}
			} else {
				this.forceUpdate();
			}

		}

		// on first load we don't wait for decode, just splat it out there asap
		if(this.firstLoad){

			loadImage.decoding = "async";
			loadImage.onload = onFinish;
			loadImage.onerror = this.props.onError;

			loadImage.src = src;


		} else {
			loadImage.decoding = "async";
			
			loadImage.src = src;
			loadImage.onerror = this.props.onError;
			
			loadImage.decode().then(result=>{
				onFinish();

			}).catch(e=>{
				// add image reference anyway; we'll still need the src later
				// console.log('image decode canceled: ', e, loadImage.src, this.state.src)
				onFinish();

			})

		}

	}
	
	onSizeChange = () => {

		let src = this.props['dynamic-src'] || this.props.src || undefined;

		const width = this.props.renderWidth;
		const height = this.props.renderHeight;

		const breakpointW = width < 500 ? 50 : 250;
		const srcWidth = Math.ceil(width / breakpointW) * breakpointW;

		const breakpointH = height < 500 ? 50 : 250;
		const srcHeight = Math.ceil(height / breakpointH) * breakpointH;

		// prefer model src over prop src
		if( this.props.model && srcWidth !== 0 && srcHeight !== 0 ){
			src = getImageWithOptions({w: srcWidth, h: srcHeight}, this.props.model).url;
		}

		if( this.props.isLazyLoadable && src){
			this.decodeImage(src);
		} 

		this.setState((prevState)=>{

			if( src == prevState.src || !src){
				return null
			}

			this.firstLoad = false;

			return {
				lastSrc: prevState.src,
				src: src,
			}
		})

	}
}

export default 	forwardRef((props, ref) => {

	let model = props.model?.is_video && props.model?.poster ? props.model.poster : props.model
	// don't pass through model that can't be loaded
	if( model && model.loading ){
		model = null;
	}
	return <Image {...props} model={model} mediaRef={ref} />;
})
