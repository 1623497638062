import _ from 'lodash';
import { combineReducers } from 'redux';
import { actionTypes } from "../actions";
import { helpers } from '@cargo/common';

let localStorageCartContents = {};

if(!helpers.isServer) {

	try {
		localStorageCartContents = JSON.parse(localStorage.getItem('cargo-commerce-cart')) || {};

		if(
			window.__PRELOADED_STATE__ 
			&& window.__PRELOADED_STATE__.commerce 
			&& window.__PRELOADED_STATE__.commerce.cart
		) {
			// delete from preloaded state so it won't overwrite what we are pulling from localstorage here
			delete window.__PRELOADED_STATE__.commerce.cart;
		}

	} catch(e) {}
}

function cart(state = localStorageCartContents, action) {

	switch(action.type) {

		case actionTypes.RESET_COMMERCE_CART: 

			try {
				localStorage.removeItem('cargo-commerce-cart')
			} catch(e) {
				console.error('Unable to store cart', e)
			}

			return {};

		break;

		case actionTypes.ADD_COMMERCE_PRODUCT_TO_CART: 

			let newItem = action.payload;
			let newState;

			// delete from cart
			if(newItem.quantity === null) {

				newState = {...state};
				delete newState[newItem.variant_id];

			} else {

				// already in the cart
				if(state[newItem.variant_id]) {

					// just up the amount
					newItem = {
						...state[newItem.variant_id],
						quantity: Math.max(0, (state[newItem.variant_id].quantity || 0) + newItem.quantity)
					}

				}

				newState = {
					...state,
					[newItem.variant_id]: newItem
				}

			}

			try {
				localStorage.setItem('cargo-commerce-cart', JSON.stringify(newState))
			} catch(e) {
				console.error('Unable to store cart', e)
			}

			return newState;

		break;

	}

	return state;
};

const unescapeHtmlEntitiesInProduct = product => {

	if(product.name) {
		product.name = _.unescape(product.name);
	}

	product.variants?.forEach(variant => {
		// overwrite all keys, otherwise order will change
		_.each(variant.attributes, (value, attrName) => {
			const unescapedValue = _.unescape(value);
			delete variant.attributes[attrName];
			variant.attributes[_.unescape(attrName)] = unescapedValue;
		})
	})

	_.each(product.attributes, (value, attrName) => {
		const unescapedItemsArr = value.map(item => {
			return _.unescape(item);
		  });
		// overwrite all keys, otherwise order will change
		delete product.attributes[attrName];
		product.attributes[_.unescape(attrName)] = unescapedItemsArr;
	})

}

function products(state = {}, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_COMMERCE_PRODUCTS_FULFILLED: 

			const options = action.meta?.options || {};

			const productsByID = (action.payload.data?.data || []).reduce(
				(map, product, index) => {

					const existingProduct = state[product?.product_id];

					if(options.onlyNew && existingProduct) {

						if(options.idArray === null && existingProduct.sort_index === undefined) {
							// set sort index on the existing product if not already there
							existingProduct.sort_index = index;
						}

						// product already exists, don't update the state in this location
						return map;
					}

					// set sorts 
					if(options.idArray === null) {
						product.sort_index = index;
					}

					unescapeHtmlEntitiesInProduct(product);

					return {...map, [product.product_id]: product}
				}
				, {}
			)

			return {
				...state,
				...productsByID
			};

		case actionTypes.COMMERCE_PRODUCT_UPDATED: 

			unescapeHtmlEntitiesInProduct(action.payload);

			return {
				...state,
				[action.payload.product_id]: action.payload
			}

		break;

		case actionTypes.COMMERCE_PRODUCT_DESTROYED: 

			const newState = {...state};

			delete newState[action.payload.product_id];

			return newState

		break;

	}

	return state;
};


function shop(state = {}, action) {

	if(!action.payload) {
		return state;
	}

	switch(action.type) {

		case actionTypes.FETCH_SHOP_MODEL_FULFILLED: 

			return {
				...state,
				...action.payload.data
			}

		break;

		case actionTypes.SHOP_MODEL_UPDATED: 

			return {
				...state,
				...action.payload
			}

		break

	}

	return state;
};


export const rawReducers = {
	products,
	cart,
	shop
}

export default combineReducers(rawReducers);