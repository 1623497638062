import { Component, createRef }from 'preact';
import selectors from "../../../../../selectors";
import windowInfo from "../../../../window-info.js";

import { backdropSettings, helpers } from "@cargo/common";
import _ from 'lodash';

/**
 * The React view that can render a backbone view.
 */

const imports = {
	'legacy/slitscan': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/slitscan/main.js`)
	},
	'legacy/refraction': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/refraction/main.js`)
	},
	'legacy/badtv': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/badtv/main.js`)
	},
	'legacy/gmaps': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/gmaps/main.js`)
	},
	'legacy/halftone': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/halftone/main.js`)
	},
	'legacy/kaleidoscope': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/kaleidoscope/main.js`)
	},
	'legacy/morphovision': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/morphovision/main.js`)
	},
	'legacy/parallax': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/parallax/main.js`)
	},
	'legacy/pixelation': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/pixelation/main.js`)
	},
	'legacy/polygon_engine': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/polygon_engine/main.js`)
	},
	'legacy/ripple': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/ripple/main.js`)
	},
	'legacy/video': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/video/main.js`)
	},
	'legacy/wave': () => {
		return import(`${PUBLIC_URL}/_jsapps/backdrop/wave/main.js`)
	}
}

const loadBackboneView = _.memoize(path => {

	return new Promise(async (resolve, reject) => {

		if(helpers.isServer) {
			return reject();
		}

		// first load dependencies
		await init();

		// then load the view
		const { default: view } = await imports[path]();

		resolve(view);

	});

});

class BackboneViewController extends Component {

	constructor(props) {

		super(props);

		this.ref = createRef();


		// start init as soon as possible
		loadBackboneView(this.props.path).catch(e => {
			return;
		});

	}

	componentDidMount() {
		this.initView();
	}

	initView = async () => {

		// While we wait make sure we dont' interact with old backdrop
		delete this.backboneView;

		// load view
		const view = await loadBackboneView(this.props.path).catch(e => {
			return;
		});

		if(!this.ref.current) {
			console.log('No element to render backbone view to...');
			return;
		}

		const settings = backdropSettings[
			this.props.path.replace('legacy/', '')
		];

		const defaults = {
			page_id: this.props.id
		};

		_.each(settings?.default.defaults, (setting, key) => {
			defaults[key] = setting.value
		});

		this.onChangeCustom = settings?.onChangeCustom ? settings.onChangeCustom.bind(this) : null;

		this.ref.current.innerHTML = '';

		this.backboneView = new view({
			el: this.ref.current,
			model: new Backbone.Model({
				data: {...defaults, ...this.props.settings}
			}),
			...(this.props.viewOptions || {})
		});
		
		// Backbone does not set these attributes when supplying the el
		// We'll do it ourselves.
		if(this.backboneView.id) {
			this.ref.current.id = this.backboneView.id;
		}

		if(this.backboneView.className) {
			this.ref.current.className = this.backboneView.className;
		}


		// grab backdrop settings specific to each legacy backdrop and overwrite as necessary
		this.backboneView.defaults = {
			...this.backboneView.defaults,
			...this.props.defaults
		}

		// make deferred load name unique
		if(this.backboneView.deferredLoadEventName) {
			this.backboneView.deferredLoadEventName += "_" + _.uniqueId();

			Cargo.Event.once(this.backboneView.deferredLoadEventName, ()=>{

				if(this.ref.current) {
					this.ref.current.dispatchEvent(new CustomEvent('legacy-backdrop-loaded', {
						bubbles: true,
						composed: true,
						detail: {}
					}));
				}
			})
		}
		
		this.backboneView.render();

		if (typeof this.backboneView.Init === 'function') {
			this.backboneView.Init();
		}

		this.backboneView.SetSizeFromMainView?.(this.props.dimensions.width, this.props.dimensions.height)
	
		if(this.props.visibility.visible){
			this.backboneView.in_viewport = true;
			this.backboneView.Resume?.();
		} else {
			this.backboneView.in_viewport = false;				
		}


	}

	destroyView =()=>{

		if( this.backboneView ){

			if (typeof this.backboneView.destroy === 'function') {
				this.backboneView.destroy();
			}

			this.backboneView.undelegateEvents();
			this.backboneView.$el.removeData().unbind();
			this.backboneView.remove();	

			delete this.backboneView;
		}
	}

	componentWillUnmount() {
		this.destroyView();
	}

	componentDidUpdate(prevProps){

		if(this.backboneView && !_.isEqual(prevProps.settings, this.props.settings)){
			if(this.onChangeCustom){
				this.onChangeCustom(prevProps.settings, this.props.settings, this.backboneView)
			} else {
				console.log('custom backdrop update failed')
			}
		}

		// if backdrop path changed, unmount the previous view and load in the next one
		if( this.props.path != prevProps.path ){
			this.destroyView();
			this.initView();
			return
		}

		if( this.backboneView ){
			if(
				prevProps.dimensions.width != this.props.dimensions.width ||
				prevProps.dimensions.height != this.props.dimensions.height
			){
				this.backboneView.SetSizeFromMainView?.(this.props.dimensions.width, this.props.dimensions.height)
			}

			if(this.props.visibility.visible != prevProps.visibility.visible){

				if(this.props.visibility.visible){
					this.backboneView.in_viewport = true;
					this.backboneView.Resume?.();
				} else {
					this.backboneView.in_viewport = false;
					this.backboneView.Pause?.();
				}
			}
		}
	}

	render() {
		const backdropName= this.props.path?.replace('legacy/', '') || '';
		
		return <div
			data-backdrop={backdropName}
			ref={this.ref}
			dangerouslySetInnerHTML={{__html: ''}}
		/>
	}

}

export default BackboneViewController;

const init = _.once(async () => {

	if(helpers.isServer) {
		return;
	}

	const { Backbone, underscore, jQuery } = await import('https://static.cargo.site/assets/C3/legacy/backbone-packaged.min.js');

	// expose globally
	window.$ = jQuery;
	window.Backbone = Backbone;
	window._ = underscore;

	window.Cargo = {
		o: {
			collection: {},
			model: {}
		},
		Collection: {
		},
		Helper: {
			isMobile:()=>{
				return windowInfo.data.mobile.active				
			},
			IsAdminEdit:()=> {
				return helpers.isAdminEdit;
			}
		},
		Event: 	_.extend({}, Backbone.Events),
		Core: {
			Handlebars: {
				Render: ()=>{return ''}
			}		
		}
	}

	window.Backdrop = {
		Data: {

		}
	}

	Cargo.o.collection.images =  Backbone.Collection.extend({

		model: function(attrs, options){

			if(attrs && attrs.type === 'local') {
				return new Cargo.o.model.localImage(attrs, options);
			}

			return new Cargo.o.model.image(attrs, options);

		},

		/**
		 * Fetch an image from the collection with the id, based on width
		 * @param  {Int} id    Image id
		 * @param  {Int} width Image width
		 * @return {Object}    Object with url and size 
		 */
		fetchImage : function(hash, width) {

			const state = window.store.getState();
			const model = selectors.getMediaByHash(state)[hash];

			const backboneModel = new Cargo.o.model.image({...model});

			if(model && backboneModel) {
				return backboneModel.getImageWithOptions({ w : width });
			}

			// var model = this.findWhere({id : parseInt(id)});
			// 
			// if(model) {
			// 	return model.getImageWithOptions({ w : width });
			// }
		},

		/**
		 * Fetch an image with WebGL sizing from the collection with the id, based on width
		 * @param  {Int} id    Image id
		 * @param  {Int} width Image width
		 * @return {Object}    Object with url and size 
		 */
		fetchWebGLImage : function(hash, imageSize) {
			
			const state = window.store.getState();
			const model = selectors.getMediaByHash(state)[hash];

			if( !model ){
				return null;
			}

			return {
				w: model.width,
				h: model.height,
				url: 'https://freight.cargo.site/w/'+ imageSize + '/t/webgl/i/' + model['hash'] + '/' + model['name'],
				id: hash
			}

		}

	});

	/**
	 *	Default model for images
	 */
		
	Cargo.o.model.image = Backbone.Model.extend({
		
		initialize: function() {
			_.bindAll(this, 'getImageByWidth');
		},

		urlRoot: function(){
			return "/_api/v0/image";
		},

		/**
		 * Returns the name of the closest match for an image based on width
		 * @param  {Int} width 
		 * @return {String}       Image url
		 */
		getImageByWidth: function(width) {

			var imageOptions = {};

			// in case requested width is larger than the original
			if(width > this.get('width')) {
				// limit to the max image size
				width = this.get('width');
			}

			imageOptions.w = width;

			return this.getImageWithOptions(imageOptions).url;

		},

		/**
		 * Returns the name of the closest match for an image based on width_2x
		 * @param  {Int} width 
		 * @return {String}       Image url
		 */
		getImageByWidth2x: function(width) {

			return this.getImageByWidth(width * 2);

		},

		/**
		 * Get a full URL path with optional size and crop data
		 *
		 * All options available:
		 * img.getImageWithOptions({
		 * 		w : 800,			// width of 800
		 * 		h : 500,			// height of 500
		 * 		q : 50,				// quality of 50%
		 * 		t : 'original',		// original image
		 * 		webgl : 1024 		// WebGL square version
		 * 		square : true, 		// Crop to square
		 * 		c : { 				// Crop to specific dimensions
		 * 			x : 0,
		 * 			y : 0,
		 * 			w : 200,
		 * 			h : 100
		 * 		}
		 * 
		 * });
		 *
		 * Returns: 
		 * {
		 * 		w : 800,
		 * 		h : 500,
		 * 		retina : false,
		 * 		square : false,
		 * 		url : '/path/to/image',
		 * 		c : { (same as input) }
		 * }
		 *
		 * Real world usage:
		 * img.getImageWithOptions({ w : 800 }).url;
		 * 
		 * @param  {Object} custom_options Options object (see above)
		 * @return {Object}                Return object
		 */
		getImageWithOptions: function(custom_options){

			// defaults, that can be overwritten by the custom options
			var options = {
				retina: window.devicePixelRatio ? window.devicePixelRatio >= 1.2 ? true : false : false,
				square: false,
				url: '',
				webgl: (custom_options.hasOwnProperty('t') && custom_options.t == "webgl")
			};

			_.extend(options, custom_options);

			// original images are not retina
			if(options.t === 'original') {
				options.retina = false;
			}

			// Double size and set quality for retina, but not if we want webgl
			if(options.retina === true && !options.webgl) {

				// if q is not set, and retina is requested, lower the quality to reduce
				// file size.
				if(options.q == undefined) {
					options.q = 75;
				}

				// double dimensions, but limit at the original dimensions
				if(options.hasOwnProperty('w')) {
					options.w = Math.min(options.w * 2, this.get('width'));
				}

				if(options.hasOwnProperty('h')) {
					options.h = Math.min(options.h * 2, this.get('height'));
				}

			} 

			// Validate that the image size we are requesting is not bigger than what we have
			if(options.w && options.w > this.get('width')) {
				// If this is a webgl image, make sure we can take it
				if(options.webgl) {
					options.w = this.getValidWebGLSize(options.w);
				
				// Otherwise, just use the original size of the image
				} else {
					options.w = this.get('width');
				}
			}

			if(options.h && options.h > this.get('height')) {
				options.h = this.get('height');
			}

			// Square image requested, make a crop
			if(options.square === true) {
				
				var crop = this.getSquareCrop();

				options.c = {
					x: crop.x,
					y: crop.y,
					w: crop.w,
					h: crop.h
				}

			}

			var validParamKeys = ['t', 'w', 'h', 'q', 'c'];

			// build the final URL with all settings
			var urlParams = [],
				w_val = false,
				h_val = false;

			_.each(options, function(val, key){

				// only add valid params to the URL
				if(validParamKeys.indexOf(key) === -1 || val === "") {
					return;
				}
				
				if(key === "c") {

					urlParams.push('c/' + val.x + '/' + val.y + '/' + val.w + '/' + val.h);

				} else {
					
					urlParams.push(key + '/' + val);

				}

				// Check for height and width attributes, used for return value
				w_val = (key == "w") ? val : (key == "c" && val.w) ? val.w : w_val;
				h_val = (key == "h") ? val : (key == "c" && val.h) ? val.h : h_val;

			}, this);

			// Get the final width and height
			var scale_size = this.getScaleSize(w_val, h_val);
			options.w = scale_size.w;
			options.h = scale_size.h;

			if(this.get('hash') !== undefined && this.get('name') !== undefined) {
				// Construct the URL
				options.url = 'https://freight.cargo.site/' + urlParams.join('/') + '/i/' + this.get('hash') + '/' + this.get('name');
			} else {
				options.url = '';
			}

			return options;

		},

		getSquareCrop: function(){

			var x = y = w = h = diff = 0;

			if(this.get('height') > this.get('width')) {
	            w = h = this.get('width');
	            
	            diff = this.get('height') - this.get('width');
	            y = Math.floor(diff/2);
	            x = 0;
	        
	        // Width is bigger then height
	        } else if(this.get('width') > this.get('height')) {
	            w = h = this.get('height');
	            
	            diff = this.get('width') - this.get('height');
	            x = Math.floor(diff/2);
	            y = 0;

	        // Square
	        } else  {
	            w = this.get('width');
	            h = this.get('height');
	            x = y = 0;
	        }


	        return {
	            'w' : w,
	            'h' : h,
	            'x' : x,
	            'y' : y
	        };

		},

		getOriginalImage: function() {

			return this.getImageWithOptions({
				't': 'original',
				'retina' : false
			});

		},

		urlBelongsToImage: function(url) {

			if(typeof url !== "string") {
				return false;
			}

			// check if url contains the hash and name. If so, the url points to a version
			// of this image.
			return url.indexOf(this.get('hash') + '/' + this.get('name')) !== -1;

		},

		/**
		 * Validate a requested webgl image size
		 * @param  {Int} w Desired width
		 * @return {Int}   
		 */
		getValidWebGLSize: function(w) {
			var sizes = [128, 256, 512, 1024, 2048, 4096];

			// The requested width is greater than available
			if(w > this.get('width')) {
				// Loop through the size list and get the largest size available
				_.each(sizes, function(size) { 
					if(size < 1000) {
						w = size; 
					}
				});
			}

			return w;
		},

		/**
		 * Get the scaled size for this image
		 * Returns the width and height based on original and 1 value
		 */
		getScaleSize : function (w, h) {
			var og_w,
				og_h,
				out_w = w,
				out_h = h;
			
			og_w = parseInt(this.get('width'));
			og_h = parseInt(this.get('height'));

			// Values already set, do nothing
			if(w && h) {
			
			// Original width and height are the same
			} else if(og_w == og_h) {
				out_w = (w) ? w : (h) ? h : og_w;
				out_h = out_w;
			
			// We have the width only
			} else if(w) {
				var scale_percent = og_w/w,
					out_h = Math.floor(og_h/scale_percent),
					out_w = w;
			
			// We have the height only
			} else if(h) {
				var scale_percent = og_h/h,
					out_w = Math.floor(og_w/scale_percent),
					out_h = h;
			}

			return {
				w : out_w,
				h : out_h
			};
		},


		/**
		 * Get a fit and scaled size for this image
		 * used by the backdrop when we have a wonky, or unkown, image size
		 */
		getFitSize : function (og_w, og_h, w, h) {
			og_w = parseInt(og_w);
			og_h = parseInt(og_h);

			if(og_h > og_w) {
				var scale_percent = og_w/w,
					out_h = Math.floor(og_h/scale_percent),
					out_w = w;
			} else if(og_w > og_h) {
				var scale_percent = og_h/h,
					out_w = Math.floor(og_w/scale_percent),
					out_h = h;

			} else {
				var out_w = og_w,
					out_h = og_h;
			}
			return [out_w, out_h];
		}
	});

	Cargo.Collection.Images = new Cargo.o.collection.images();

});