import _ from "lodash";
import selectors from "./selectors";
import { helpers } from '@cargo/common';
import { options as preactOptions } from 'preact';


export const getImageWithOptions = (options = {}, model) =>{

	// defaults, that can be overwritten by the custom options
	const defaults = {
		retina: window.devicePixelRatio ? window.devicePixelRatio >= 1.2 ? true : false : false,
		square: false,
		url: '',
		webgl: (options.hasOwnProperty('t') && options.t == "webgl")
	};

	options = _.extend(defaults, options);

	// original images are not retina
	if(options.t === 'original') {
		options.retina = false;
	}

	// Double size and set quality for retina, but not if we want webgl
	if(options.retina === true && !options.webgl) {

		// if q is not set, and retina is requested, lower the quality to reduce
		// file size.
		if(options.q == undefined) {
			options.q = 75;
		}

		// double dimensions, but limit at the original dimensions
		if(options.hasOwnProperty('w')) {
			options.w = Math.min(options.w * 2, model['width']);
		}

		if(options.hasOwnProperty('h')) {
			options.h = Math.min(options.h * 2, model['height']);
		}

	} 

	// Validate that the image size we are requesting is not bigger than what we have
	if(options.w && options.w > model['width']) {
		// If this is a webgl image, make sure we can take it
		// if(options.webgl) {
			// options.w = this.getValidWebGLSize(options.w);
		
		// Otherwise, just use the original size of the image
		// } else {
			options.w = model['width'];
		// }
	}

	if(options.h && options.h > model['height']) {
		options.h = model['height'];
	}

	// Square image requested, make a crop
// 		if(options.square === true) {
// 			
// 			var crop = this.getSquareCrop();
// 
// 			options.c = {
// 				x: crop.x,
// 				y: crop.y,
// 				w: crop.w,
// 				h: crop.h
// 			}
// 
// 		}

	var scale_size = getScaleSize(options.w, options.h, model);
	options.w = scale_size.w;
	options.h = scale_size.h;

	var validParamKeys = ['t', 'w', 'h', 'q', 'c'];

	// build the final URL with all settings
	var urlParams = [];

	_.each(options, function(val, key){

		// only add valid params to the URL
		if(validParamKeys.indexOf(key) === -1 || val === "") {
			return;
		}
		
		if(key === "c") {

			urlParams.push('c/' + val.x + '/' + val.y + '/' + val.w + '/' + val.h);

		} else {
			
			urlParams.push(key + '/' + val);

		}
		// cropping currently unused anywhere in the frontend
		// Check for height and width attributes, used for return value
		// w_val = (key == "w") ? val : (key == "c" && val.w) ? val.w : w_val;
		// h_val = (key == "h") ? val : (key == "c" && val.h) ? val.h : h_val;

	}, this);


	// Get the final width and height


	if(model['hash'] !== undefined && model['name'] !== undefined && scale_size.w > 0) {
		// Construct the URL
		options.url = 'https://freight.cargo.site/' + urlParams.join('/') + '/i/' + model['hash'] + '/' + model['name'];
	} else {
		options.url = '';
	}

	return options;

}

/**
 * Get the scaled size for this image
 * Returns the width and height based on original and 1 value
 */
export const getScaleSize = (w, h, model) => {
	let out_w = w,
		out_h = h;
	
	const og_w = parseInt(model['width']),
		  og_h = parseInt(model['height']);

	// Original width and height are the same
	if(og_w == og_h) {
		out_w = (w) ? w : (h) ? h : og_w;
		out_h = out_w;
	
	// We have the width only
	} else if(w && !h ) {
		const scale_percent = og_w/w;

		out_h = Math.floor(og_h/scale_percent);
		out_w = w;
	
	// We have the height only
	} else if(h && !w) {
		const scale_percent = og_h/h;

		out_w = Math.floor(og_w/scale_percent);
		out_h = h;
	} else {
		const scale_percent = Math.min(og_h/h, og_w/w);
		out_w = Math.floor(og_w/scale_percent);
		out_h = Math.floor(og_h/scale_percent);
	}

	return {
		w : out_w,
		h : out_h
	};
}


// https://github.com/facebook/fbjs/blob/main/packages/fbjs/src/core/shallowEqual.js
export function shallowEqual(objA, objB) {
	if (Object.is(objA, objB)) {
		return true;
	}

	if (typeof objA !== 'object' || objA === null ||
		typeof objB !== 'object' || objB === null) {
		return false;
	}

	const keysA = Object.keys(objA);
	const keysB = Object.keys(objB);

	if (keysA.length !== keysB.length) {
		return false;
	}

	// Test for A's keys different from B.
	for (let i = 0; i < keysA.length; i++) {
		if (
			!hasOwnProperty.call(objB, keysA[i]) ||
			!Object.is(objA[keysA[i]], objB[keysA[i]])
		) {
			return false;
		}
	}

	return true;
}

export const treeWalker = (structure, pages, sets, currentSet, index = 0, options = {}) => {

	// store these things in options so they're
	// persistent between recursive calls
	options.shared = options.shared || {};

	const idsByIndex = (
		// grab list of ids belonging to this parent set
		structure.byParent[currentSet.id] || []
	).reduce((acc, id) => {
		// reduce the list of ids to a map of 'index' keys and 'id' values
		acc[structure.indexById[id]] = id;
		return acc;
	}, {});

	while(index >= 0 && index < currentSet.page_count && options.shared.hasBreak !== true) {

		const id = idsByIndex[index];

		// if we have an id, find the content belonging to that id
		const content = id && (pages.byId[id] || sets.byId[id]);

		if(
			!(content?.page_type === "set" && options.includeSets !== true)
		) {
			const callbackResult = options.callback?.(currentSet.id, index, content);

			if(callbackResult === 'break') {
				options.shared.hasBreak = true;
				break;
			}
		}

		// if the content at this index is a set
		if(content?.page_type === "set") {
			
			// traverse into the set, starting at the end or beginning depending on the direction we're going
			treeWalker(structure, pages, sets, content, options.reverse === true ? content.page_count - 1 : 0, {
				...options,
				traverseUp: false
			});

		}
		
		// move forwards or backwards
		options.reverse === true ? index-- : index++;

	}

	// reached the end or beginning of a set. Move up to it's parent and
	// continue walking the tree from before or after the position of the current set
	if(
		options.traverseUp !== false
		&& (
			(options.reverse === true && index < 0)
			|| (options.reverse === false && index > currentSet.page_count - 1)
		)
	) {

		// find out 
		const parentSet = sets.byId[_.findKey(structure.byParent, children => children.includes(currentSet.id))];
		const currentSetIndex = structure.indexById[currentSet.id];

		if(parentSet && currentSetIndex !== undefined) {
			treeWalker(structure, pages, sets, parentSet, currentSetIndex + (options.reverse === true ? -1 : 1), options)
		}

	}

}

// https://github.com/rainydio/memoize-weak/blob/master/lib/memoize.js
export function memoizeWeak(fn) {

	if(helpers.isServer) {
		// disable memoization on the server so data can't leak between requests
		return fn;
	}

	let root;
	let noargsCalled;
	let noargsResult;

	const reset = () => {
		root = {
			primitiveResults: null,
			referenceResults: null,
			primitiveArgs: null,
			referenceArgs: null
		};
		noargsCalled = false;
		noargsResult = undefined;
	};

	reset();

	const memoized = function memoized() {
		const last = arguments.length - 1;

		if (last === -1) {
			if (noargsCalled === false) {
				noargsResult = fn.call(this);
				noargsCalled = true;
			}
			return noargsResult;
		}

		let node = root;

		for (let i = 0; i < last; i += 1) {
			const arg = arguments[i];
			const primitive =
				arg === null || (typeof arg !== "object" && typeof arg !== "function");

			let argsMap;

			if (primitive) {
				if (node.primitiveArgs === null) {
					argsMap = node.primitiveArgs = new Map();
				} else {
					argsMap = node.primitiveArgs;
				}
			} else {
				if (node.referenceArgs === null) {
					argsMap = node.referenceArgs = new WeakMap();
				} else {
					argsMap = node.referenceArgs;
				}
			}

			if (argsMap.has(arg) === false) {
				node = {
					primitiveResults: null,
					referenceResults: null,
					primitiveArgs: null,
					referenceArgs: null
				};
				argsMap.set(arg, node);
			} else {
				node = argsMap.get(arg);
			}
		}

		let result;

		const arg = arguments[last];
		const primitive =
			arg === null || (typeof arg !== "object" && typeof arg !== "function");

		let resultsMap;

		if (primitive) {
			if (node.primitiveResults === null) {
				resultsMap = node.primitiveResults = new Map();
			} else {
				resultsMap = node.primitiveResults;
			}
		} else {
			if (node.referenceResults === null) {
				resultsMap = node.referenceResults = new WeakMap();
			} else {
				resultsMap = node.referenceResults;
			}
		}

		if (resultsMap.has(arg)) {
			result = resultsMap.get(arg);
		} else {
			result = fn.apply(this, arguments);
			resultsMap.set(arg, result);
		}

		return result;
	};

	memoized.clear = function clear() {
		const last = arguments.length - 1;
		if (last === -1) {
			reset();
			return;
		}

		let node = root;

		for (let i = 0; i < last; i += 1) {
			const arg = arguments[i];
			const primitive =
				arg === null || (typeof arg !== "object" && typeof arg !== "function");
			const argsMap = primitive ? node.primitiveArgs : node.referenceArgs;
			if (argsMap === null) {
				return;
			}
			node = argsMap.get(arg);
		}

		const arg = arguments[last];
		const primitive =
			arg === null || (typeof arg !== "object" && typeof arg !== "function");

		if (primitive) {
			if (node.primitiveArgs !== null) {
				node.primitiveArgs.delete(arg);
			}
			if (node.primitiveResults !== null) {
				node.primitiveResults.delete(arg);
			}
		} else {
			if (node.referenceArgs !== null) {
				node.referenceArgs.delete(arg);
			}
			if (node.referenceResults !== null) {
				node.referenceResults.delete(arg);
			}
		}
	};

	return memoized;
};



const originalDebounceRenderingOption = preactOptions.debounceRendering;

let pendingRender = null;
export const pausePreactRendering = ()=>{
	preactOptions.debounceRendering = (render)=>{
		pendingRender = render;
	}
}

// pass true through if you do not want to immediately render after unpausing
export const resumePreactRendering = (doNotRenderImmediately)=>{
	if(pendingRender && !doNotRenderImmediately){
		pendingRender();
	}
	pendingRender = null;
	preactOptions.debounceRendering = originalDebounceRenderingOption 
}



const vnodeDiffListeners = new WeakMap();
const originalPreactDiffedOption = preactOptions.diffed;

preactOptions.diffed = vnode => {

	if(vnodeDiffListeners.has(vnode)) {
		vnodeDiffListeners.get(vnode)(vnode.__e);
	}
	
	// Call previously defined hook if there was any
	if (originalPreactDiffedOption) {
		originalPreactDiffedOption(vnode);
	}
}


export const listenToVnodeDiff = (vnode, callback) => {

	vnodeDiffListeners.set(vnode, callback);

	// return a method to unsubscribe
	return () => {
		vnodeDiffListeners.delete(vnode);
	};

}

export const getMobileOffsetsString = (stylesheet, propertySets) => {

	if(!stylesheet) {
		return null;
	}

	let mobileOffsetsString = '';

	let getVal = (property, rule) => {
		let storedValue = rule.style?.getPropertyValue(property);
		return storedValue !== '' && storedValue !== undefined ? storedValue : null ;
	}

	let customTextStyleSelectors = [];

	_.each(stylesheet.rules, rule => {

		let selector = rule.selectorText;
		let computedProperties = {}
		let hasCalcValue = false;
		let isTextStyle = false;
		let isMobileSelector = false;

		if( rule.cssText && rule.cssText.includes('--text-style') ){ 
			isTextStyle = true;
			customTextStyleSelectors.push(selector+' a')
			customTextStyleSelectors.push(selector+' a:hover')
		} 

		if( rule.cssText ){
			_.each( customTextStyleSelectors, (selectorFromArray) => {
				if( rule.cssText.includes( selectorFromArray ) ){
					isTextStyle = true;
				}
			})
		}

		if (rule?.selectorText?.includes('.mobile')) {
			isMobileSelector = true;
		}

		_.each(propertySets, set => {

			let inDenyList = false;

			if (Array.isArray(set.denyList)) {
				set.denyList.forEach((deny) => {
					if (deny.includes('match:') && selector?.match(deny.replace('match:', ''))) {
						inDenyList = true;
					}
					if (selector && selector === deny) {
						inDenyList = true;
					}
				})
			}

			let inAllowList = false;
			if (Array.isArray(set.allowList)) {
				set.allowList.forEach((allow) => {
					if (allow.includes('match:') && selector?.match(allow.replace('match:', ''))) {
						inAllowList = true;
					}
					if (selector && selector === allow) {
						inAllowList = true;
					}
				})
			} else {
				inAllowList = true;
			}

			// Text styles should never recieve 
			// additional or dynamic padding on mobile
			if( isTextStyle || isMobileSelector ){
				inDenyList = true; 
			}

			if ( inDenyList === true || inAllowList === false ) {
				return;
			}
	
			computedProperties = set.properties.reduce((prev, curr) => {
				if (curr) {
					prev[curr] = getVal(curr, rule);
				}
				return prev;
			}, computedProperties);

		})

		const newSelector = '.mobile ' + selector;
		const existingRule = Array.from(stylesheet.rules).find(rule => rule.selectorText === newSelector);
		// If a rule with a matching selector already exists, bail
		if (existingRule) {
			// For each computed property, check if the existing rule has a value for it and if it does, unset it on the computedProperties object
			Object.keys(computedProperties).forEach((property) => {
				const existingValue = getVal(property, existingRule);
				if (existingValue) {
					computedProperties[property] = null;
				}
			})
		}

		if (
			Object.values(computedProperties).every(element => element === null) || 
			Object.values(computedProperties).length === 0
		) {
			return;
		}

		// selector and opening bracket
		mobileOffsetsString += newSelector + ' { ';
		_.each(computedProperties, (propertyValue, propertyName)=>{
			if (propertyValue) {
				const calcValue = propertyValue.split(' ').reduce((prev, curr, index) => {
					return prev + ` calc(${curr} * var(--mobile-padding-offset))`;
				}, '');
				mobileOffsetsString += `${propertyName}: ${calcValue};`;
			}
		})
		mobileOffsetsString += ' } ';

	});

	return mobileOffsetsString;
}