import { Component, createRef } from "preact";
import axios from 'axios';
import { API_ORIGIN, WEB_HOSTNAME } from "@cargo/common";
import { isProduction } from "@cargo/common/helpers";
import _ from 'lodash';

class DigitalProductDownloadForm extends Component {
	constructor(props) {

		super(props);

		this.state = {
			error: false
		}

		this.inputRef = createRef();
	}

	submit = (e) => {
		
		e.preventDefault();

		this.setState({
			error: false
		});

		const email = this.inputRef.current?.value;

		if(!email) {
			return this.setState({
				error: 'Email address is not valid.'
			});
		}

		axios.post(`https://${isProduction ? WEB_HOSTNAME : 'dev.cargo.site'}/c3filedownload/validate/${email}/${this.props.hash}`).then(result => {

			if(result?.data?.jdata?.valid) {
				// valid email, download the file
				return window.location.href = `https://${isProduction ? WEB_HOSTNAME : 'dev.cargo.site'}/c3filedownload/download/${email}/${this.props.hash}`;
			}

			this.setState({
				error: result?.data?.error || 'Email address is not valid.'
			});

		}).catch(err => {
			this.setState({
				error: 'Email address is not valid.'
			})
		});

	}

	onKeyDown = (e) => {
		this.setState({
			error: false
		})
	}

	render(){
		return (<>
			<style>
				{`

					body {
						letter-spacing: 0;
						padding: 30px;
						color: rgba(0,0,0,.85);
						font-size: 18px;
						line-height: 1;
						font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif, "Sans Serif", Icons;
					}

					.message {
						margin-bottom: 15px;
					}

					.error {
						color: #f00;
					}

					input[type="email"] {
						border-radius: 100px;
						border: 1px solid rgba(0,0,0,0.75);
						padding: 10px 20px;
						font-family: Helvetica;
						font-size: 18px;
						font-style: normal;
						font-weight: 400;
						line-height: 21px;
						letter-spacing: -0.004em;
						text-align: left;
						width: 340px;
					}

					input[type="email"]:placeholder {
						color: rgba(0,0,0,0.75);
					}
					input[type="email"]::-webkit-input-placeholder {
						color: rgba(0,0,0,0.75);
					}
					input[type="email"]::-moz-placeholder {
						color: rgba(0,0,0,0.75);
					}
					input[type="email"]:-moz-placeholder {
						color: rgba(0,0,0,0.75);
					}
					
					.submit {
						-webkit-appearance: none;
						border: none;
						cursor: pointer;
						font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
						font-size: 14.5px;
						white-space: nowrap;
						display: flex;
						margin: 0;
						width: 130px;
						justify-content: flex-start;
						padding: 6.5px 12px 7.5px 15px;
						font-weight: 500;
						flex: 1 1;
						text-align: center;
						height: auto;
						min-height: 28.5px;
						line-height: 1;
						border-radius: 100px;
						user-select: none;
						position: relative;
						background: #262626;
						color: #fff;
						padding-right: 5px;
						margin-top: 10px;
					}
					.submit:active {
						opacity: .7;
					}
				`}
			</style>
			
			<span className="message">Please verify your email address:</span> 

			<form noValidate onSubmit={this.submit}>
				<input 
					name="email" 
					type="Email"
					placeholder="Email address..." 
					autofocus="true" 
					onKeyDown={this.onKeyDown}
					ref={this.inputRef}
					formNoValidate
				/>
				
				<button className="submit" type="submit">Submit</button>
			</form>
			
			{this.state.error && (<>
				<br/><span class="error">{this.state.error}</span>
			</>)}
		</>)
	}
}

export default DigitalProductDownloadForm
